import React from 'react';
import { Container, Grid, Typography, Link } from '@material-ui/core';
import GitHubIcon from '@mui/icons-material/GitHub';

const Footer = () => {
  return (
    <footer style={{ marginTop: "1rem", height: "200px", backgroundColor: "#f5f5f5", color: "#444444" }}>
      <Container style={{ height: "100%" }}>
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
          <Typography variant="body2" align="center">
            <strong>
              Local Projects by{' '}
              <Link href="https://github.com/ProjectHammer" color="inherit" underline="none">
                <GitHubIcon /> GitHub
              </Link>
              . The source code is licensed{' '}
              <Link href="https://localproject.app" color="inherit" underline="none">
                by localproject.app
              </Link>
              . The website content is licensed{' '}
              <Link href="https://localproject.app" color="inherit" underline="none">
                localproject.app
              </Link>
              .
            </strong>
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;