import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import CardMedia from '@mui/material/CardMedia';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PanoramaIcon from '@mui/icons-material/Panorama';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import CreateMessage from '../components/CreateMessage';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { getInbox, addFriends } from '../services/InboxService';
import { TweetButton, WhatsAppButton } from '../components/ShareButtons';

const UserProjectHeader = ({ user, realUser, realUserImg }) => {
  const nav = useNavigate();
  const [open, setOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [checkFriends] = useState([""]);
  const [userImg, setUserImg] = useState("");
  const [location, setLocation] = useState("");
  const [userBg, setUserBg] = useState("");
  const [status, setStatus] = useState("");
  const [visitorImg, setVisitorImg] = useState("");

  const addNewFriend = () => {
    if (!realUser) {
      nav('/Login');
    } else {
      const newFriend = realUser;
      const data = { friend: newFriend, user, isFriend: 0, visitorImg };
      const checkFriend = checkFriends.find(element => element.friends === realUser);
      Swal.fire({
        title: `Add ${user} to friend List?`,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Request',
        denyButtonText: 'Another time',
      }).then((result) => {
        if (result.isConfirmed) {
          if (checkFriend && checkFriend.isFriend === 0) {
            Swal.fire('Already Requested!', '', 'success');
          } else {
            addFriends(data);
            Swal.fire('Sent Request!', '', 'success');
          }
        }
      });
    }
  };

  const uploadImages = () => {
    nav(`/UploadImages`, { state: { user, realUser } });
  };

  useEffect(() => {
    const fetchUser = async () => {
      const balls = { user };
      const inboxData = await getInbox(balls);
      const [firstImage] = inboxData[0].userImg;
      setUserBg(firstImage.imgBg);
      setUserImg(firstImage.imgLink);
      setStatus(firstImage.comments);
      setLocation(firstImage.location);
    };
    fetchUser();
  }, [user, realUser]);

  useEffect(() => {
    const img = new Image();
    img.src = userImg;
    img.onload = () => {
      setImageLoading(false);
    };
    img.onerror = () => {
      setUserImg('');
      setImageLoading(false);
    };
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [userImg]);

  return (
    <>
      <Card style={{ marginTop: "1rem" }}>
        <CardMedia
          component="img"
          imageRendering="optimizeSpeed"
          alt={imageLoading}
          style={{ objectFit: "cover", height: '5rem' }}
          image={userBg}
        />
        <div style={{ marginTop: "-40px", marginLeft: "5rem" }}>
          <TweetButton text="Check out this Project @" user={user} style={{ marginRight: '10px' }} />
        </div>
        <div style={{ marginTop: "-40px", marginLeft: "16rem" }}>
          <WhatsAppButton text="Check out my new project at" user={user} style={{ marginRight: '10px' }} />
        </div>
        <Avatar
          alt="User Avatar"
          imageRendering="optimizeSpeed"
          src={userImg}
          sx={{ width: 140, height: 140, top: "-4.5rem", marginLeft: "7rem", marginBottom: "4rem", border: "0.5rem solid white" }}
        />
        <Typography mt={-25} ml={31.5} variant="h6" color="textSecondary">
          {user}
        </Typography>
        <Typography ml={31.5} variant="body2" color="textSecondary">
          {location}
        </Typography>
        <Typography ml={31.5} variant="body2" color="textSecondary">
          {status}
        </Typography>
        <div style={{ marginLeft: "5rem", display: 'flex', alignItems: 'center' }}>
          <Button onClick={uploadImages} style={{ marginRight: '10px', marginBottom: "10px" }}>
            <PanoramaIcon />
          </Button>
          {user !== realUser && (
            <div>
              <Button value={realUser} onClick={addNewFriend} style={{ marginRight: '10px' }}>
                <PersonAddAlt1Icon />
              </Button>
              <Button onClick={() => setOpen(true)} style={{ marginRight: '10px' }}>
                <MessageIcon />
              </Button>
            </div>
          )}
        </div>
      </Card>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="large"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <CreateMessage user={user} realUser={realUser} realUserImg={realUserImg} />
        </Alert>
      </Collapse>
    </>
  );
};

export default UserProjectHeader;