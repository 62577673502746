import React, { useEffect, useState } from 'react'
import HomePageBar from '../components/HomePageBar'
import Tooltip from '@mui/material/Tooltip'
import ListIcon from '@mui/icons-material/List'
import CardMedia from '@mui/material/CardMedia';
import ReplyIcon from '@mui/icons-material/Reply'
import EditIcon from '@mui/icons-material/Edit'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
import ButtonGroup from '@mui/material/ButtonGroup'
import projectIcon from '../components/projectIcon'
import ChipDelete from '@mui/joy/ChipDelete';
import Box from '@mui/material/Box';
import AddTaskIcon from '@mui/icons-material/AddTask'
import ShareLocationIcon from '@mui/icons-material/ShareLocation'
import DeleteIcon from '@mui/icons-material/Delete'
import Menu from '@mui/material/Menu'
import AddBoxIcon from '@mui/icons-material/AddBox'
import PanoramaIcon from '@mui/icons-material/Panorama'
import Textarea from '@mui/joy/Textarea';
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import SocialDistanceIcon from '@mui/icons-material/SocialDistance'
import Chip from '@mui/joy/Chip';
import Divider from '@mui/material/Divider'
import FlagCircleIcon from '@mui/icons-material/FlagCircle'
import LoginBarTop from '../components/LoginBarTop'
import CoinbaseCommerceButton from 'react-coinbase-commerce'
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid';
import UserProjectHeader from './UserProjectHeader'
import UserBlogs from '../components/UserBlogs'
import Card from "@material-ui/core/Card";
import { distance } from '../components/CalculateDistance'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useNavigate } from 'react-router-dom'
import { addProjectMembers, removeMember, addDonations, deleteProjectComment, deleteProjectReply, editProject, getInbox, removeProject, sendProjectComment, sendProjectCommentReply } from '../services/InboxService'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useLocation } from 'react-router-dom'
import { TextField, Typography } from '@mui/material'
import { getLocation } from '../services/UserService'
import { useParams } from 'react-router-dom'
import { uploadSharedProjects } from '../services/SharedService'
import { ProjectPictures } from '../components/ProjectPictures'
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import Checklist from '../components/CheckList/CheckList';

const UserProject = ({ removeData,otherUser }) => {

  const nav = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const state = location.state || "";
  const realUser = state.realUser || otherUser;

  const user = state.user ? state.user : userId;

  if (location.pathname === `/UserProjects`) {
    removeData = 0;
  }
  
  if (location.pathname === `/UserProjects/${user}`) {
    removeData = 0;
  }

  const [projects, setProjects] = useState([""]);
  const [userImg, setUserImg] = useState([""]);
  const [realUserLocation, setRealUserLocation] = useState();
  const [realUserLat, setRealUserLat] = useState("");
  const [realUserLon, setRealUserLon] = useState("");
  const [deleteComments, setDeleteComments] = useState(false);
  const [visitorImg, setVisitorImg] = useState([""]);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState([]);
  const [theUser, setTheUser] = useState();
  const [theRealUser, setTheRealUser] = useState();
  const [projectComments, setProjectComments] = useState();
  const [comment, setComment] = useState(false);
  const [checkProjectDelete, setCheckProjectDelete] = useState([""]);
  const [coverImgTwo, setCoverImgTwo] = useState([""]);
  const [replyText, setReplyText] = useState("");
  const [activeCommentId, setActiveCommentId] = useState(null); // Track the ID of the currently active reply area
  const [charge, setCharge] = useState(null);
  const [donationAmount, setDonationAmount] = useState(0);
  const [donorName, setDonorName] = useState('');
  const [showDonation, setShowDonation] = useState(false);
  const handleReplyTextChange = (event) => setReplyText(event.target.value);


  const handleCharge = async (e) => {
    const chargeData = {
      name: { realUser },
      description: `Donate to ${user}`,
      pricing_type: 'fixed_price',
      local_price: {
        amount: '0.0003',
        currency: 'BTC'
      },
      metadata: {
        custom: 'metadata'
      }
    };
    setDonorName(realUser)
    setDonationAmount(0.0003);
    setShowDonation(true)
    const newImg = "";
    const projectName = e.currentTarget.id;
    const data = { name: projectName, realUser, user, ammount: "0.0003", imgLink: newImg }
    const newCharge = await createCharge(chargeData);
    setCharge(newCharge);
    const hostedUrl = newCharge.data.hosted_url;
    window.open(hostedUrl, '_blank', 'height=600,width=800');
    setTimeout(async () => {
      const updatedCharge = await getCharge(newCharge.data.code);
      if (updatedCharge.data.status === 'COMPLETED') {
        addDonations(data);
      }
    }, 50000);
  };
  const createCharge = async (chargeData) => {
    const response = await fetch('https://api.commerce.coinbase.com/charges', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CC-Api-Key': '7ab0b3e0-b676-47cb-8b64-01c8b4c0dfe0',
        'X-CC-Version': '2018-03-22',
      },
      body: JSON.stringify(chargeData),
    });
    const charge = await response.json();
    return charge;
  };
  const getCharge = async (chargeCode) => {
    const response = await fetch(`https://api.commerce.coinbase.com/charges/${chargeCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CC-Api-Key': '7ab0b3e0-b676-47cb-8b64-01c8b4c0dfe0',
        'X-CC-Version': '2018-03-22',
      },
    });
    const charge = await response.json();
    return charge;
  };
  useEffect(() => {
    const fetchUser = async () => {
      const loggedUser = { user };
      const imgRealUser = { user: realUser };
      setTheRealUser(realUser)
      setTheUser(user)
      // Get blocked friends and reset if necessary
      // Get user inbox data
      const inboxData = await getInbox(loggedUser);
      if (inboxData) {
        const newInboxData = [...inboxData]
        setProjects(newInboxData);
        setCoverImgTwo(inboxData[0].projects[0]?.imgProject[inboxData[0].projects[0].imgProject.length - 2]);
        // Get visitor inbox data
      } else {
        setProjects("");
      }
      const visitorInboxData = await getInbox(imgRealUser);
      if (visitorInboxData && Array.isArray(visitorInboxData) && visitorInboxData.length > 0) {
        const firstUserImg = visitorInboxData[0].userImg;
        if (firstUserImg && Array.isArray(firstUserImg) && firstUserImg.length > 0) {
          setUserImg(firstUserImg[0].imgLink);
          setVisitorImg(firstUserImg[0].imgLink);
          setRealUserLocation(firstUserImg[0].location);
        } else {
          console.error('Invalid userImg structure in visitorInboxData.');
        }
      } else {
        console.error('Invalid visitorInboxData structure or it is empty.');
      }
      const search = await getLocation(realUserLocation);
      if (search) {
        const realUserLat = search[0]?.lat;
        const realUserLon = search[0]?.lon;
        setRealUserLat(realUserLat);
        setRealUserLon(realUserLon);
      }
    };
    fetchUser();
    return () => { };
  }, [user, realUser, realUserLocation, comment, checkProjectDelete, deleteComments, description, donorName]);

  const getData = (ort) => {
    const lat = [];
    for (const key in ort) {
      if (Object.hasOwnProperty.call(ort, key)) {
        const element = ort[key];
        lat.push(element);
      }
    }
  }

  const uploadImages = () => {
    nav(`/UploadImages`, { state: { user: user, realUser } });
  }

  const gotoMember = (e) => {

    if (e) {
      nav(`/UserProjects`, { state: { user: e, realUser } });
    } else {
      console.log("No User Slected")
    }
  }

  const addMember = (event) => {
    if (realUser === "" || undefined) {
      nav('/Login');
    } else {
      const project = event.currentTarget.title;
      const name = event.currentTarget.id;
      const theUserProfile = user;
      const data = { project, user: realUser, name, isMember: 0, theUserProfile, imgLink: visitorImg };
      Swal.fire({
        title: 'Request to join ' + name + ' project?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Request',
        denyButtonText: `Another time`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire('Requested!', '', 'success')
          addProjectMembers(data);
          setComment(prevComment => !prevComment);
        } else if (result.isDenied) {
          //Swal.fire('not now', '', 'info')
        }
      })
    }
  }

  const gotoTasks = (e) => {
    nav(`/CreateTask`, { state: { user: realUser, realUser } });
  }

  const removeMemberProject = (e) => {
    const name = e.currentTarget.id;
    const _id = e.currentTarget.value;
    const data = { user: realUser, _id }
    Swal.fire({
      title: 'Delete ' + name + ' Member?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Deleted! ' + name + ' success')
        removeMember(data)
        setComment(prevComment => !prevComment);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const delProject = (e) => {
    const projectName = e.currentTarget.id;
    const projectN = e.target.textContent;
    if (projectName) {
      // Use the projectName variable here
    } else {
      console.warn("The clicked element does not have an ID.");
    }
    // const deletProject = e.target.value;
    let delProjectData = { user: realUser, projectId: projectName }
    Swal.fire({
      title: 'Delete ' + projectN + ' project?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Deleted! ' + projectN + ' success')
        removeProject(delProjectData);
        setCheckProjectDelete(delProjectData);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const ShareUserProject = async (e) => {
    
    if(e.dude[0] === "" | e.dude[1] === "" | realUser === undefined){
      
      Swal.fire("Sorry you have no Map location");
    }else{
      
      const sharedItem = e.dude + "," + e.projectName + "," + realUser + "," + e.userImg + "," + coverImgTwo || ""
      const name = e.projectName
      if (sharedItem !== undefined) {
        Swal.fire({
          zIndex:999,
          title: 'Share ' + name + ' on the map?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Share',
          denyButtonText: `No`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.fire('Shared!', '', 'success')
            uploadSharedProjects(sharedItem);
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info');
          }
        })
      } else {
        Swal.fire({
          title: 'Please try again!',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Ok',
          denyButtonText: `No`,
        });
      }
    }
   
  }

  const handleChangeDescription = (e) => {
    const _id = e.target.id;
    const description = e.target.value;
    let data = { user: user, description, _id };
    //Updates inbox with linked images
    editProject(data).then(success => {
      setDescription(data.description);
      setComment(prevComment => !prevComment);
    });
  }

  const getComments = async (e) => {
    const name = e.target.value;
    const theMessage = projectComments;
    const time = new Date().toLocaleTimeString();
    const day = new Date().toLocaleDateString('en-UK');
    try {
      const data = { user: realUser, theMessage, name, imgLink: visitorImg, time: day + "@" + time };
      const sendMessage = await sendProjectComment(data);
      if (sendMessage) {
        setDeleteComments(prevComment => !prevComment);
        Swal.fire({
          title: 'Message Sent',
          text: 'Your comment has been sent successfully.',
          icon: 'Congrats!',
          confirmButtonText: 'Close'
        });
      }
    } catch (error) {
      console.error('Error adding comment to the database:', error);
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while sending your comment.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
    }
  };

  const deleteComment = (e) => {
    const name = e.target.value;
    const delComment = e.target.id;
    const data = { user, name, delComment }
    Swal.fire({
      title: 'delete Comment',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Deleted!', '',)
        deleteProjectComment(data);
        setDeleteComments(prevComment => !prevComment);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    })
  }

  const deleteReply = (e) => {
    const name = e.target.value;
    const delComment = e.target.id;
    const data = { user, name, delComment }
    Swal.fire({
      title: 'delete Comment',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProjectReply(data);
        setDeleteComments(prevComment => !prevComment);
        Swal.fire('Deleted!', user,)
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    })
  }

  const reportComment = (e) => {
    const name = e.target.value;
    const delComment = e.target.id;
    const data = { user, name, delComment }
    Swal.fire({
      title: 'report Comment',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire('Reported!', '',)
        deleteProjectComment(data);
        setDeleteComments(prevComment => !prevComment);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    })
  }

  const Details = function getMessages(projects) {
    const newTitle = [];
    const ort = [];
    for (let i = 0; i < projects.length; i++) {
      const inbox = projects[i];
      const projectsList = inbox.projects
      const newMembers = inbox.members;
      for (const key in projectsList) {
        const { name: projectName, description, achieve, budget, dates, location, colorCard, colorText, createdBy, lat, lon: lng, _id: deleteProject, imgProject, comments, replies, donations } = projectsList[key];
        const unit = "M";
        const dude = [lat, lng];
        const getDistance = distance(lat, lng, realUserLat, realUserLon, unit);
        const theCalulatedDistance = [];
        const distancefromRealUser = parseInt(getDistance);
        if (projectName) {
          theCalulatedDistance.push(distancefromRealUser);
        }
        const nameMember = []
        for (const key in newMembers) {
          if (Object.hasOwnProperty.call(newMembers, key)) {
            const { _id, name: element, members: mem, imgLink: imgMember } = newMembers[key];
            if (element === projectName) {
              nameMember.push({ _id, user: mem, imgLink: imgMember });
            }
          }
        }
        const projectComments = [];
        const repliess = [];
        const donationss = [];
        let totalDonations = 0; // Initialize total amount variable
        const donationsArray = Object.values(donations);
        for (const donation of donationsArray) {
          const { user, ammount, imgLink, } = donation ?? {};
          if (ammount) {
            const parsedAmount = parseFloat(ammount);
            if (!isNaN(parsedAmount)) {
              if (donationss.length >= 5) {
                donationss.shift(); // Remove the oldest donation from the array
              }
              donationss.push(donation); // Add the new donation to the array
              totalDonations += parsedAmount;
            }
          }
        }

        const startIndex = donationss.length > 5 ? donationss.length - 5 : 0;
        donationss.splice(0, startIndex); // Remove all elements except the last 5
        for (const comment of Object.values(comments)) {
          const { _id, name: commento, comment: userComment, user: newUser, imgLink, time } = comment ?? {};
          // Find the replies that match the current comment
          for (const reply of Object.values(replies)) {
            if (reply.commentId === _id) {
              repliess.push(reply);
            }
          }
          if (commento && commento.includes(projectName)) {
            // Add the main comment to `projectComments` with the replies array
            const commentObj = { _id, commento, userComment, newUser, imgLink, time };
            projectComments.push(commentObj);
          }
        }
        const sendReplyToComment = async (e) => {
          try {
            const data = { commentId: e._id, name: e.commento, user: realUser, theMessage: replyText, imgLink: visitorImg };
            await sendProjectCommentReply(data);
            setComment(prevComment => !prevComment);
          } catch (error) {
            console.error('Error adding comment to the database:', error);
          }
        };
        const DonationList = ({ donationss }) => {
          const lastFiveDonations = donationss.slice(-3);
          return (
            <div style={{ marginTop: "10px" }}>
              {lastFiveDonations.map((donation) => (
                <div key={donation._id}>
                  <h1 variant="body2" color="text.secondary" style={{ marginLeft: "5px" }}>{donation.user || "Anony.."} donated {donation.ammount} BTC &#10003;</h1>
                </div>
              ))}
            </div>
          );
        }
        const shareMapData = {
          dude,
          projectName,
          createdBy,
          userImg,
        };
        const shared = { location: dude, projectName, description, createdBy:realUser };
        let justifyContent;
        let floatDirection;
        if (newTitle.length === 3) {
          justifyContent = 'center';  // Center the content for 3 elements
          floatDirection = newTitle.length % 3 === 3 ? 'left' : 'right';
        } else {
          justifyContent = 'center';  // Align content to the start for 2 or 4 elements
          floatDirection = newTitle.length % 1 === 0 ? 'left' : 'right';  // Float direction based on the condition
        }
        newTitle.push(
            <><Button></Button><Card style={{ backgroundColor: colorCard }}>
            <div style={{ position: 'relative' }}>
              <CardMedia
                component="Img"
                alt=""
                style={{ objectFit: "cover", height: '6rem' }}
                image={coverImgTwo} />
              <PopupState popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button style={{ color: colorCard, float: "right", marginRight: "1rem", top: "-4rem" }} variant="outlined" {...bindTrigger(popupState)}>
                      <ListIcon></ListIcon>
                    </Button>
                    <Menu
                      {...bindMenu(popupState)}>
                      <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                        {realUser !== "" ?
                          (<Tooltip title="Join Project" arrow><Button style={{ color: colorCard }} title={deleteProject} id={projectName}
                            onClick={addMember}><AddBoxIcon />
                          </Button></Tooltip>) : null}
                        {theRealUser === theUser ? (
                          <Button style={{ color: colorCard }} value={projectName}
                            onClick={delProject} id={deleteProject}><DeleteIcon />
                          </Button>) : null}
                        {theRealUser === theUser ? (<Tooltip title="Share on Map" arrow><Button style={{ color: colorCard }} type="submit" id={shared.projectName} value={JSON.stringify(shareMapData)} onClick={() => ShareUserProject(shareMapData)}><ShareLocationIcon /></Button></Tooltip>) : null}
                        <Button style={{ color: colorCard }} onClick={uploadImages}><PanoramaIcon /><span></span></Button>
                        {theRealUser === theUser ? (<Tooltip title="Edit Description" arrow><Button style={{ color: colorCard }} type="submit" onClick={() => setIsEditing(true)}><EditIcon></EditIcon></Button></Tooltip>) : null}
                        {theRealUser === theUser ? (<Tooltip title="Add Tasks" arrow><Button style={{ color: colorCard }} id={createdBy} name={projectName} onClick={gotoTasks}><AddTaskIcon></AddTaskIcon></Button></Tooltip>) : null}
                      </ButtonGroup>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </div>
            <CardContent item>

              <Typography display="flex" justifyContent="flex-end" variant="body1" color={colorText}>
                {dates}
              </Typography>

              <Typography style={{ marginTop: "10px" }} variant="h4" color={colorText}>
                {projectName}
              </Typography>
              <Typography variant="body1" color={colorText}>
                {location}
              </Typography>
              {nameMember.length === 0 ? (
                <div className="array-empty-message"></div>
              ) : (
                <div className="avatar-container">
                  {nameMember.map((nameMember, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <Avatar src={nameMember.imgLink} alt="volunteer" value={nameMember.user} onClick={gotoMember} />
                      {theRealUser === theUser ? (
                        <button value={nameMember._id} id={nameMember.user} className="delete deleteMember" onClick={removeMemberProject}></button>
                      ) : null}
                    </div>
                  ))}
                </div>
              )}

              <ProjectPictures imgProject={imgProject} projectName={projectName} />
              <Typography style={{ marginTop: "10px" }} variant="body1" color={colorText}>
                {budget}
              </Typography>
              <Typography style={{ marginTop: "10px" }} variant="body1" color={colorText}>
                {achieve}
              </Typography>
              <Typography style={{ marginTop: "10px" }} variant="body1" color={colorText}>
                {isEditing ? (
                  <form className="subtitle">
                    <textarea
                      style={{ minHeight: "100px", width: "100%" }}
                      cols="60"
                      rows="5"
                      id={projectName}
                      type="text"
                      onChange={handleChangeDescription}
                      placeholder="Update description"
                      defaultValue={description} />
                    {theRealUser === theUser && (
                      <Button variant="contained" className="buttonStyle" onClick={() => setIsEditing(false)}>
                        Update
                      </Button>
                    )}
                  </form>
                ) : (
                  <div>
                    <div className="clickable" onClick={() => setIsEditing(true)}>{description}</div>
                  </div>
                )}
              </Typography>
              <Grid
                style={{
                  marginTop: "10px",
                  minWidth: "20rem",
                  maxHeight: "20rem",
                  overflowY: 'auto', // Allows scrolling
                  scrollbarWidth: 'none', // For Firefox
                  msOverflowStyle: 'none' // For Internet Explorer and Edge
                }}
                sx={{
                  '&::-webkit-scrollbar': {
                    display: 'none' // Hides scrollbar for Webkit browsers (Chrome, Safari)
                  }
                }}
              >
                {projectComments.map((value, index) => (
                  <>
                    <div style={{ float: "right" }}>
                      {realUser !== undefined && realUser !== "" ? (<FlagCircleIcon type="button" id={value._id} style={{ display: "block", cursor: "pointer", color: colorText }} value={projectName} title="Report" onClick={reportComment}></FlagCircleIcon>) : null}
                    </div>
                    <div class="media-content">
                      <div class="content">
                        <p>
                          <strong style={{ color: colorText }}>{value.newUser}</strong> <small style={{ color: colorText }}>{value.name}</small> <small style={{ color: colorText }}>{value.time}</small>  {theRealUser === theUser ? (<Chip
                            size="sm"
                            color="danger"
                            endDecorator={<ChipDelete style={{ cursor: "pointer", marginLeft: "-0.5rem", color: colorCard }} id={value._id} value={projectName} onDelete={() => deleteComment} />}
                          >
                          </Chip>
                          ) : null}
                          <br />
                          <div>
                            <div
                              key={index}
                              style={{
                                color: colorCard,
                                display: "inline-flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                marginTop: index === 0 ? "1rem" : "0rem",
                                marginLeft: index === 0 ? "1rem" : "0rem",
                                backgroundColor: "whitesmoke",
                                borderRadius: "1rem",
                                padding: "0.5rem 1rem",
                                marginRight: "10px",
                                whiteSpace: "nowrap",
                                wordWrap: "break-word",
                              }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={value.imgLink}
                                sx={{ width: '35px', height: '35px', border: "0.1rem solid white" }} />
                              <Typography colour={colorCard} mt={0.5} ml={1}> {value.userComment}</Typography>
                            </div>
                            {repliess.filter((reply) => reply.commentId === value._id).map((reply, index) => (
                              <><div style={{
                                backgroundColor: colorText,
                                marginTop: index === 0 ? "0rem" : "0rem",
                                marginLeft: index === 0 ? "3rem" : "3.5rem"
                              }} className='dotted-line'></div><div
                                key={index}
                                style={{
                                  color: colorCard,
                                  display: "inline-flex",
                                  flexDirection: "row",
                                  alignItems: "flex-start",
                                  marginTop: index === 0 ? "0rem" : "0rem",
                                  marginLeft: index === 0 ? "1.5rem" : "2rem",
                                  backgroundColor: "whitesmoke",
                                  borderRadius: "10px",
                                  padding: "5px 10px",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  wordWrap: "break-word",
                                }}
                              >
                                  <Avatar
                                    alt="Remy Sharps"
                                    src={reply.imgLink}
                                    sx={{ width: '35px', height: '35px', border: "0.1rem solid white" }} />
                                  <Typography
                                    style={{
                                      color: colorCard,
                                      padding: "5px 10px",
                                      marginRight: "10px",
                                      WebkitTextStroke: "0.1px ",
                                    }}
                                  >
                                    {reply.comment}
                                  </Typography>
                                  <Chip id={reply._id} style={{ color: colorCard }} value={projectName} class="delete" onClick={() => gotoMember(reply.user)}
                                    startDecorator={reply.user}
                                    endDecorator={<ChipDelete id={reply._id} value={projectName} onClick={() => deleteReply} />} />
                                </div>
                              </>
                            ))}
                          </div>
                          {realUser !== undefined && realUser !== "" ? (<Button sm onClick={() => setActiveCommentId(value._id)} variant='text'><ReplyIcon style={{ color: colorText, height: "-1rem", float: "right", marginRight: "0.5rem" }} onClick={() => setActiveCommentId(value._id)} /></Button>) : null}
                          {activeCommentId === value._id && (
                            <>
                              <div style={{ position: 'relative' }}>
                                <Textarea placeholder='Write a comment' minRows={2} maxRows={4} variant="soft" value={replyText} onChange={handleReplyTextChange} style={{
                                  marginTop: "10px", color: colorCard,
                                }} />
                                {realUser !== undefined && <Button
                                  style={{
                                    float: "right",
                                    marginRight: "1rem",
                                    marginTop: "-3.1rem",
                                    color: colorCard,
                                    border: "none",
                                    boxShadow: "0px 2px 5px 1px rgba(0, 0, 0, 0.25)",
                                  }}
                                  onClick={() => sendReplyToComment(value, replyText)}
                                >
                                  Reply
                                </Button>}
                              </div>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <Divider className="buttonStyle" style={{ marginBottom: "10px" }} />
                  </>
                ))}
              </Grid>
              <Divider style={{ marginBottom: "10px", color: colorText }} />
              {realUser !== undefined && realUser !== "" ? (
                <TextField
                  style={{
                    alignSelf: "auto",
                    marginTop: "5px",
                    marginLeft: "5px",
                    width: "100%",
                    color: colorText,
                  }}
                  InputProps={{
                    style: {
                      "&::placeholder": {
                        color: colorText,
                      },
                      color: colorText,
                    },
                  }}
                  placeholder={`${realUser} Send Message`}
                  variant="standard"
                  onChange={(event) => setProjectComments(event.target.value)}
                  inputProps={{ maxLength: 30 }} />
              ) : null}
              {realUser !== undefined && realUser !== "" ? (<Button variant='outlined' style={{ marginTop: "10px", float: "right", color: colorText }} name={projectName} value={projectName} onClick={getComments}>Send</Button>) : null}
              {realUser !== undefined && realUser !== "" ? (<Checklist></Checklist>) : null}
              {dude[0] !== "" && <Typography className="subtitle" style={{ marginTop: "10px", float: "left", color: colorText }}>{location} <SocialDistanceIcon /> {distancefromRealUser} KM from {realUser}</Typography>}
              {dude[0] !== "" && <MapContainer center={dude} zoom={17} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={dude} icon={projectIcon}>
                  <Popup>
                    {description}
                  </Popup>
                </Marker>
              </MapContainer>}
              {showDonation && (
                <div>
                  Thank you, {realUser}
                </div>
              )}
              <DonationList donationss={donations}></DonationList>
              <div style={{ float: "right", marginBottom: "10px", marginTop: "10px", color: colorText }}><CurrencyBitcoinIcon /> {totalDonations.toFixed(4)}</div>
              {charge ? (
                <>
                  <CoinbaseCommerceButton style={{ color: "white", backgroundColor: "textSecondary", border: '1px solid black' }}
                    chargeId={charge.id}
                    onChargeComplete={() => {
                      swal("Donation complete!");
                    } } />
                  <span className="button-text"></span>
                </>
              ) : (
                <>
                  <Button variant='text' id={projectName} style={{ marginTop: "10px", marginBottom: "10px", marginRight: "10px", color: "white", backgroundColor: "#3f51b5" }} onClick={handleCharge}>
                    Donate
                  </Button><progress className="progress is-small" value={totalDonations} max={100} /></>
              )}
            </CardContent>
          </Card></>
         
        )
      }
      getData(ort);
      if (removeData === 1) {
        return newTitle.splice(-1);
      } else {
        return newTitle
      }
    }
  }
  const newDetails = Details(projects);
  return (
    <>
      {realUser !== undefined && realUser !== "" ? (
        <>
          {removeData === 0 && <LoginBarTop realUser={realUser} />}
        </>
      ) : <HomePageBar />}
      {removeData === 0 && <UserProjectHeader realUserImg={userImg} user={user} realUser={realUser} />}
      <>
      <div>
   
        {newDetails !== undefined && newDetails.map((newDetail, index) => (
          <div key={index}>{newDetail}</div>
        ))}
        </div>
      </>
    </>
  )
}
export default UserProject;