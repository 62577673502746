import React, { useEffect, useState } from 'react';
import {
  Button, Card, CardActions, CardContent, TextField, Grid, Typography
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { HuePicker } from 'react-color';
import Swal from 'sweetalert2';
import { createProject } from '../services/InboxService';
import { getLocation } from '../services/UserService';
import Dates from './Dates';
import { Textarea } from '@mui/joy';

const pastelColors = [
  '#ffd6e0', '#f4e3e3', '#A2C8E7', '#B5EAD7', '#9FEAE5', '#C3FFB8',
];

const CreateProject = ({ realUser }) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [location, setLocation] = useState('');
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');
  const [date, setDate] = useState([null, null]);
  const [text, setText] = useState('');
  const [selectedColor, setSelectedColor] = useState(pastelColors[0]);
  const [textColor, setTextColor] = useState('black');

  const { register, handleSubmit } = useForm();
  const nav = useNavigate();
  const [aLoading, isALoading] = useState(false);
  const user = realUser;

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = async () => {
    isALoading(true);
    console.log(`Searching for ${inputValue}`);

    if (!inputValue) {
      Swal.fire({
        title: "Error",
        text: "Please enter a search term.",
        icon: "error",
      });
      isALoading(false);
      return;
    }

    try {
      const search = await getLocation(inputValue);
      const latValue = search[0]?.lat;
      const lonValue = search[0]?.lon;
      if (latValue && lonValue) {
        setLat(latValue);
        setLon(lonValue);
        setLocation(search[0]?.display_name);
      } else {
        Swal.fire({
          title: "Location Not Found",
          text: "No location data found for the entered search term.",
          icon: "error",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while retrieving location.",
        icon: "error",
      });
      console.error('Error occurred while retrieving location:', error);
    }
    isALoading(false);
  };

  const onSubmit = async (data, e) => {
    setLoading(true);
    try {
      data.user = realUser;
      data.dates = date || "";
      data.lon = lon || "";
      data.lat = lat || "";
      data.location = location || "";
      data.imgLogo = "";
      data.colorCard = selectedColor || "";
      data.colorText = textColor || "";

      if (!data.name) {
        Swal.fire('No project information');
      } else {
        await createProject(data);
        const result = await Swal.fire({
          title: `See Project ${data.name}?`,
          showDenyButton: true,
          confirmButtonText: `Go to ${data.name} project`,
        });
        if (result.isConfirmed) {
          nav(`/UserProjects`, { state: { user: realUser, realUser } });
          e.target.reset();
        } else if (result.isDenied) {
          e.target.reset();
        }
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
    setLoading(false);
  };

  if (user === null) {
    nav('/');
  }

  const SearchIconWrapper = styled('div')(({ theme, aLoading }) => ({
    padding: theme.spacing(0.5, 2),
    position: 'absolute',
    zIndex: '999',
    animation: `${aLoading ? 'spin 1s linear infinite' : 'none'}`,
  }));

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Card sx={{ backgroundColor: "#e4f3e0", maxWidth: "600px", width: "100%", padding: "20px" }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Create a New Project
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('name')}
              inputProps={{ maxLength: 30 }}
              fullWidth
              style={{ marginTop: '10px' }}
              label="Project name"
              variant="outlined"
              placeholder="Project name"
              required
            />
            <Textarea
              style={{ marginTop: '10px' }}
              minRows={3}
              label="Description"
              variant="outlined"
              {...register('description')}
              placeholder="Define what you’re looking to achieve in the project"
              required
            />
            <Textarea
              style={{ marginTop: '10px' }}
              minRows={3}
              label="Tasks to get the job Done"
              variant="outlined"
              {...register('achieve')}
              placeholder="Determine the tasks you need to complete in the project"
              required
            />
            <Textarea
              style={{ marginTop: '10px' }}
              minRows={2}
              label="More Info"
              placeholder='More Info'
              variant="outlined"
              {...register('budget')}
              required
            />
            <TextField
              fullWidth
              style={{ marginTop: '10px' }}
              {...register('website')}
              inputProps={{ maxLength: 30 }}
              label="Website"
              variant="outlined"
              placeholder="https://www.example.com"
            />
            <Dates updateParentDateState={setDate} />
            <div>
              <TextField
                fullWidth
                style={{ marginTop: '10px' }}
                inputProps={{ maxLength: 30 }}
                label="Map Sharing Location"
                variant="outlined"
                type="text"
                value={inputValue}
                placeholder='Not Required only for Business or real project'
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <Button>
                      <SearchIconWrapper aLoading={aLoading} onClick={handleSearch}>
                        <SearchIcon />
                      </SearchIconWrapper>
                    </Button>
                  ),
                }}
              />
              <Typography variant='body2' style={{ marginTop: '10px' }}>{location}</Typography>
            </div>
            <input style={{ display: 'none' }}
              {...register('createdBy')}
              value={user}
            />
            <input style={{ display: 'none' }}
              {...register('user')}
              value={user}
            />
            <div style={{ marginTop: '0.5rem' }}>
              <TextField
                fullWidth
                style={{ marginBottom: "0.5rem" }}
                type="text"
                inputProps={{ maxLength: 23 }}
                placeholder="Test Card Colour on Text"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {pastelColors.map((color, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: color,
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      margin: '0 8px',
                      cursor: 'pointer',
                      border: selectedColor === color ? '2px solid black' : 'none',
                    }}
                    onClick={() => setSelectedColor(color)}
                  />
                ))}
                <HuePicker
                  color={selectedColor}
                  onChangeComplete={(color) => setSelectedColor(color.hex)}
                />
              </div>
            </div>
            <Typography variant="body2" style={{ color: textColor, marginTop: '8px', backgroundColor: selectedColor, padding: '8px', textAlign: 'center' }}>
              {text || "Example Text"}
            </Typography>
            <CardActions style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.5rem" }}>
              <Button
                type="button"
                size="small"
                variant="outlined"
                color="warning"
                onClick={() => nav(-1)}
                style={{ fontFamily: 'Arial', fontWeight: "bolder", borderWidth: "2px" }}
              >
                Close
              </Button>
              <LoadingButton
                type="submit"
                size="small"
                color="secondary"
                loading={loading}
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
                style={{ fontFamily: 'Arial', fontWeight: "bolder" }}
              >
                Create
              </LoadingButton>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CreateProject;