import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1rem",
    backgroundColor: '#e4f3e0', // Pastel color that goes with green and blue
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250,
    padding: '1rem',
    textAlign: 'center',
    color: '#444444',
  },
}));

const WrapSection = ({ quote }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className='centered-text'>
        {quote}
      </div>
    </Paper>
  );
}

export default WrapSection;