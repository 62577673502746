import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SimpleSlide from './SimpleSlide';
import AndroidIcon from '@mui/icons-material/Android';
import PolicyIcon from '@mui/icons-material/Policy';
import SearchSkills from './SearchSkills';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import AppleSpinner from '../components/AppleSpinner';

const HomePageBar = () => {
  const nav = useNavigate();
  const [showButton, setShowButton] = useState(false);
  const [isServerUp] = useState(true);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setIsOpen(true);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setIsOpen(false);
    setAnchorElNav(null);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (isOpen && !event.target.closest('#menu-appbar')) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('click', handleDocumentClick);
    } else {
      document.removeEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpen]);

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: "#ffffff", color: "#444444" }}>
        <AppleSpinner />
        <Toolbar disableGutters style={{ maxHeight: "100px", width: "100%" }}>
          <Button aria-label="Home" variant="text" onClick={() => nav('/')}>
            <HomeIcon />
          </Button>
          <Box sx={{ display: { xs: 'none', md: 'flex', justifyContent: 'center' } }}>
            <div onClick={handleCloseNavMenu} sx={{ my: 2, display: 'block' }}>
              <Button size="large" variant="text" onClick={() => nav('/SneakPeak')}>App</Button>
              <Button size="large" variant="text" onClick={() => nav('/Blogs')}>Blogs</Button>
              <Button size="large" variant="text" onClick={() => nav('/Jobs')}>Jobs</Button>
              <Button size="large" variant="text" onClick={() => nav('/GainRewards')}>Rewards</Button>
              <Button size="large" variant="text" onClick={() => nav('/OurMission')}>Mission</Button>
              <Button size="large" variant="text" onClick={() => nav('/Tweets')}>Tweets</Button>
              <Button size="large" variant="text" onClick={() => nav('/Products')}>Products</Button>
              <Button size="large" variant="text" onClick={() => nav('/WhyVolunteer')}>Volunteer</Button>
              <Button size="large" variant="text" onClick={() => nav('/Privacy')}><PolicyIcon /></Button>
              <Button size="large" variant="text" onClick={() => nav('/ContactUs')}><ContactSupportIcon /></Button>
              <Button size="large" variant="text" onClick={() => nav('/AboutUs')}><InfoIcon /></Button>
            </div>
          </Box>
          <SearchSkills />
          <Button sx={{ marginLeft: 'auto', backgroundColor: "#3f51b5", color: "#ffffff" }} aria-label="Login" variant="contained" onClick={() => nav('/Login')}>
            <LoginIcon />
          </Button>
          <IconButton
            sx={{ marginLeft: "1rem" }}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
          >
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <ButtonGroup orientation="vertical" aria-label="vertical outlined button group" onClick={handleCloseNavMenu}>
              <Button size="large" variant="text" onClick={() => nav('/Blogs')}>Blogs</Button>
              <Button size="large" variant="text" onClick={() => nav('/Jobs')}>Jobs</Button>
              <Button size="large" variant="text" onClick={() => nav('/GainRewards')}>Rewards</Button>
              <Button size="large" variant="text" onClick={() => nav('/OurMission')}>Mission</Button>
              <Button size="large" variant="text" onClick={() => nav('/Products')}>Products</Button>
              <Button size="large" variant="text" onClick={() => nav('/WhyVolunteer')}>Volunteer</Button>
              <Button size="large" variant="text" onClick={() => nav('/SneakPeak')}><AndroidIcon /></Button>
              <Button size="large" variant="text" onClick={() => nav('/Privacy')}><PolicyIcon /></Button>
              <Button size="large" variant="text" onClick={() => nav('/ContactUs')}><ContactSupportIcon /></Button>
            </ButtonGroup>
          </Menu>
        </Toolbar>
        <div id="status-container" title="Server Status">
          <div className="status-squares">
            {Array(345).fill(0).map((_, index) => (
              <div className={`square ${isServerUp ? 'green' : 'red'}`} key={index}></div>
            ))}
          </div>
        </div>
      </AppBar>
      <SimpleSlide style={{ zIndex: 300 }} />
      {showButton && (
        <Button sx={{ backgroundColor: "#3f51b5", color: "#ffffff", bottom: "20px", zIndex: "1000", marginLeft: "5px" }} variant="contained" onClick={scrollTop}>
          <ArrowUpwardIcon />
        </Button>
      )}
    </>
  );
};

export default HomePageBar;