import React, { useEffect, useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideocamIcon from '@mui/icons-material/Videocam';
import FormControl from '@mui/joy/FormControl';
import Typography from '@mui/material/Typography';
import LoginBarTop from './LoginBarTop';
import { useLocation } from 'react-router-dom';
import { Grid, Button, TextField } from '@mui/material';
import { userReadMessage, getInbox, deleteInbox, replyInbox, updateInboxReply, uploadRewards, editRewards } from '../services/InboxService';
import LoadingSpinner from './LoadingSpinner';
import Swal from 'sweetalert2';

const CheckInbox = ({ removeData }) => {
  const location = useLocation();
  const state = location.state;
  const user = state.user;
  const realUser = state.realUser;
  const [CheckInbox, setCheckInbox] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [countRewards, setCountRewards] = useState(true);
  const [rewards, setRewards] = useState(Number);
  const [rewardID, setRewardID] = useState();
  const [userImg, setUserImg] = useState([null]);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMessages();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (location.pathname === "/CheckInbox") {
    removeData = 0;
  }

  useEffect(() => {
    const newUser = { user };
    const fetchData = async () => {
      try {
        const inboxes = await getInbox(newUser);
        if (inboxes[0]?.userImg[0]?.imgLink) {
          setUserImg(inboxes[0].userImg[0].imgLink);
        } else {
          setUserImg("default-image-url");
        }
        let rewards = inboxes[0].rewards;
        let counterNum = 0;
        let rewardID = [];

        for (const key in rewards) {
          if (Object.hasOwnProperty.call(rewards, key)) {
            const element = rewards[key];
            let addThis = element.rewards;
            let rewardsId = element._id;
            addThis = parseInt(addThis); // Assign the parsed value back
            counterNum += addThis;
            rewardID.push(rewardsId);
          }
        }
        setRewardID(rewardID);
        setRewards(counterNum);
      } catch (error) {
        console.error('Error fetching inbox:', error);
      }
    };
    fetchData();
  }, []);

  const updateScroll = (e) => {
    containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
    const bobs = e.currentTarget.id;
    const messageIds = bobs.split(',');
    const ids = messageIds[0];
    const user = messageIds[1];
    const data = { user, ids, read: true };
    userReadMessage(data);
  };

  const EditNewRewards = (createdBy) => {
    let sum = rewards - 1;
    let frank = rewardID[0];
    let data = { user: user, rewards: sum, _id: frank };
    editRewards(data);
    let amount = 1;
    let newRewards = { user: createdBy, rewards: amount };
    uploadRewards(newRewards);
    if (rewards === 0) {
      setHidden(true);
    }
  };

  const deleteInboxs = (e) => {
    const createdBy = e.target.name;
    const _id = e.target.value;
    const users = user;
    const messageId = createdBy + users;
    let data = { user: user, createdBy: createdBy, _id: _id, messageId: messageId };
    Swal.fire({
      title: `End chatting with ${createdBy}`,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'End',
      denyButtonText: `Don't End`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInbox(data).then(response => {});
      } else if (result.isDenied) {
        Swal.fire('Keep chatting!', '', 'info');
      }
    });
  };

  const WebCamera = (e) => {
    const ChatLocation = "https://localproject-webchat.herokuapp.com/";
    if (ChatLocation.length < 1) {
      Swal.fire('No rooms available, try again soon');
    } else {
      const theTime = new Date().toLocaleTimeString();
      const createdBy = e.target.name;
      const users = user;
      const id = e.target.value;
      if (id === undefined) {
        Swal.fire('No rooms available, try again soon');
      } else {
        const Img = userImg;
        const newLink = ChatLocation + id;
        const data = { user: users, message: `${Img}  ${user} : ${newLink} Has Joined WebCamera chat @${theTime}`, createdBy: createdBy, id: id, messageId: id };
        replyInbox(data).then(response => {});
        updateInboxReply(data).then(response => {});
        window.open(newLink, 'WebChat');
      }
    }
  };

  const sendMessage = async (e) => {
    const messageInput = document.getElementById(e.target.name);
    if (messageInput.value.length < 1) {
      return;
    }
    const currentTime = new Date().toLocaleTimeString();
    const createdBy = e.target.name;
    const users = user;
    const id = e.target.value;
    const img = userImg;
    const data = {
      user: users,
      message: `${img}  ${user} : ${messageInput.value}  @${currentTime}`,
      createdBy: createdBy,
      id: id,
      messageId: id
    };
    await replyInbox(data);
    await updateInboxReply(data);
    messageInput.value = "";
    getMessages();
    setTimeout(() => {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 3000);
  };

  const getMessages = async () => {
    const Information = [];
    const balls = { user };
    try {
      await getInbox(balls).then(inboxes => {
        if (inboxes && inboxes[0].messages) {
          const messages = inboxes[0].messages;
          messages.reverse();
          Information.push(messages);
        }
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      e.target.reset();
    };
    let newTitle = [];
    if (Information[0] && typeof Information[0][Symbol.iterator] === 'function') {
      for (const i of Information[0]) {
        const message = user && i.message;
        const title = user && i.title;
        const createdByImg = user && i.createdByImg;
        const createdBy = user && i.createdBy;
        const messageId = user && i.messageId;
        const _id = user && i._id;
        const createDate = user && i.createDate;
        const both = [_id, createdBy];
        const utcDateTime = new Date(createDate);
        const euDateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const euTimeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const euDateTimeFormatter = new Intl.DateTimeFormat('en-EU', { ...euDateOptions, ...euTimeOptions });
        const euDateTime = euDateTimeFormatter.format(utcDateTime);
        newTitle.push(
          <Card style={{ marginTop: "20px", marginRight: "10px" }}>
            <Accordion onChange={updateScroll}>
              <AccordionSummary
                id={both}
                className="accordionId"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={createdByImg}
                  sx={{ width: 100, height: 100, border: "0.3rem solid white", float: "left" }}
                />
                <Button style={{ float: "right" }} value={_id} name={createdBy} onClick={deleteInboxs}>Delete</Button>
                <Typography ml={5} mt={2} variant="body1" color="textSecondary">{createdBy}  {euDateTime}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div id={both} key={_id} style={{ marginBottom: "5px", overflowWrap: "break-word", backgroundColor: "whitesmoke", overflowY: "scroll", height: "40vh" }} ref={containerRef}>
                  <div className="message-body" style={{ marginBottom: "5px", borderStyle: "none", overflowWrap: "break-word", backgroundColor: "whitesmoke" }}>
                    {message.map((message, index) => (
                      <Typography key={index} className="chat__message chat__message_A" style={{ marginLeft: "10px" }}>
                        <Avatar
                          alt="Remy Sharp"
                          src={message.split(" ")[0]}
                          sx={{ width: 50, height: 50, border: "0.1rem solid white" }}
                        />
                        {message.includes(createdBy) && <Typography className="chat__content" id={messageId} value={message}> {message.replace(message.split(" ")[0], "")} &nbsp; &nbsp;</Typography>}
                        {message.includes(user) && <Typography style={{ float: "right" }} className="chat__content_b" id={messageId} value={message}> {message.replace(message.split(" ")[0], "")} &nbsp; &nbsp;</Typography>}
                      </Typography>
                    ))}
                  </div>
                  <div className="block" style={{ marginBottom: "5px", marginTop: "12px" }}>
                    <form onSubmit={handleSubmit}>
                      <FormControl>
                        <TextField
                          multiline
                          minRows={2}
                          label="Message"
                          variant="outlined"
                          id={createdBy}
                          placeholder="Enter reply..."
                          name="message"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Button style={{ marginLeft: "auto" }} value={messageId} name={createdBy} title={title} onClick={sendMessage}>Send</Button>
                          <Button>
                            {!hidden ? (
                              <Button id="likess" onClick={() => {
                                setCountRewards(countRewards => Math.max(countRewards + 1, 0));
                                setRewards(rewards => Math.max(rewards - 1, 0));
                                setHidden(hidden);
                                EditNewRewards(createdBy);
                              }}>
                                🎖️{createdBy}
                              </Button>
                            ) : null} {countRewards}
                          </Button>
                          <Button value={messageId} name={createdBy} title={title} onClick={WebCamera}><VideocamIcon /></Button>
                        </div>
                      </FormControl>
                    </form>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Card>
        );
      }
    }
    setCheckInbox(newTitle);
  };

  return (
    <>
      {removeData === 0 && <LoginBarTop realUser={realUser} />}
      {loading ? <LoadingSpinner /> : (
        <Grid style={{ marginBlock: removeData !== 0 ? "15px" : "40px", marginRight: "0.5rem", marginLeft: "0.5rem" }}>
          {removeData === 0 && <Typography variant='h6' style={{ marginLeft: "10px" }}>You have {rewards} 🎖️ Reward Medals</Typography>}
          {CheckInbox.map((uniq, index) => <div key={index}>{uniq}</div>)}
        </Grid>
      )}
    </>
  );
};

export default CheckInbox;